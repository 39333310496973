import Vue from 'vue';
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { checkToken } from "@/api/api";
import { strIsEmpty } from "@/utils/my-utils";

export const USER_AVATAR_PICTURE_ID = "AvatarPictureId";

/**
 * 获取Token
 * pRoot            根，即Vue Component的this
 * Redirect         是否重定向
 */
export function getToken(pRoot, pRedirect){
    let vToken = Vue.ls.get(ACCESS_TOKEN);
    //console.log('npm getToken fun, the token is: ' + vToken);
    if(vToken != null && vToken.length > 0){
        return vToken;
    }else{
        if(pRedirect){
            pRoot.$router.push({name:"Login", query:{}});
        }
        return "";
    }
}
/** 检查当前token是否过期 */
export function checkLocalToken(thisObj, callback){
    let token = Vue.ls.get(ACCESS_TOKEN);
    if(!strIsEmpty(token)) {
        let params = {};
        params.token = token;
        checkToken(params).then((res) => {
            if (res.success) {
                if(res.code == 200){
                    // 0=无效   1=有效
                    let resultCheck = res.result - 0;
                    switch (resultCheck) {
                        case 0:
                            thisObj.$message.error("登陆已经过时，重新登陆...");
                            if(callback){
                                callback(false);
                            }
                            break;
                        case 1:
                            // nothing to do
                            if(callback){
                                callback(true);
                            }
                            break;
                    }
                }else{
                    thisObj.$message.error("登陆已经过时，重新登陆...");
                    if(callback){
                        callback();
                    }
                }
            } else {
                thisObj.$message.error("登陆已经过时，重新登陆...");
                if(callback){
                    callback(false);
                }
            }
        });
    }else{
        thisObj.$message.error("登陆已经过时，重新登陆...");
        if(callback){
            callback(false);
        }
    }
}

/**
 * 获取用户的头像图片ID号
 */
export function getAvatarPictureId(){
    let vPictureId = "";
    var vAvatarPictureIdLocal = Vue.ls.get(USER_AVATAR_PICTURE_ID);
    if(vAvatarPictureIdLocal == null || vAvatarPictureIdLocal == undefined){

    }else{
        vPictureId = vAvatarPictureIdLocal;
    }
    console.log('getAvatarPictureId , the pictureId=' + vPictureId);
    return vPictureId;
}
/**
 * 设置用户的头像的图片ID号
 */
export function setAvatarPictureId(pPictureId){
    console.log('setAvatarPictureId , the pictureId=' + pPictureId);
    Vue.ls.set(USER_AVATAR_PICTURE_ID, pPictureId, 7 * 24 * 60 * 60 * 1000);
}


/** 获取上传图片ID集（以逗号隔开） */
export function getUploadImageFileIds(fileList, fileStatus) {
    var fileIds = "";
    if(fileList.length > 0){
        for(let i=0; i<fileList.length; i++){
            if(fileList[i].status == fileStatus){
                if(fileIds == ""){
                    fileIds = fileList[i].uid;
                }else{
                    fileIds = fileIds + "," + fileList[i].uid;
                }
            }else {
                if (fileList[i].response != null) {
                    if (fileList[i].response.success === true) {
                        if (fileList[i].response.result.list != null && fileList[i].response.result.list.length > 0) {
                            if (fileIds == "") {
                                fileIds = fileList[i].response.result.list[0].picture_id;
                            } else {
                                fileIds = fileIds + "," + fileList[i].response.result.list[0].picture_id;
                            }
                        }
                    }
                }
            }
        }
    }
    return fileIds;
}

/** 获取上传图片ID集（以逗号隔开） */
export function getUploadFileIds(fileList, fileStatus) {
    var fileIds = "";
    if(fileList.length > 0){
        for(let i=0; i<fileList.length; i++){
            if(fileList[i].status == fileStatus){
                if(fileIds == ""){
                    fileIds = fileList[i].uid;
                }else{
                    fileIds = fileIds + "," + fileList[i].uid;
                }
            }else {
                if (fileList[i].response != null) {
                    if (fileList[i].response.success === true) {
                        if (fileList[i].response.result.list != null && fileList[i].response.result.list.length > 0) {
                            if (fileIds == "") {
                                fileIds = fileList[i].response.result.list[0].file_id;
                            } else {
                                fileIds = fileIds + "," + fileList[i].response.result.list[0].file_id;
                            }
                        }
                    }
                }
            }
        }
    }
    return fileIds;
}

/**  获取图片路径 */
export function getViewPictureUrl(baseUrl, token, pictureId, width, height) {
    let result = '';

    if(width != undefined && height != undefined){
        result = `${window._CONFIG['domianURL']}` + baseUrl + '/' + pictureId + '?width=' + width + '&height=' + height + '&client=1&token=' + token;
    }else{
        if(width == undefined && height == undefined){
            result = `${window._CONFIG['domianURL']}` + baseUrl + '/' + pictureId+ '?client=1&token=' + token;
        }else{
            // 只适配 height
            if(height != undefined){
                result = `${window._CONFIG['domianURL']}` + baseUrl + '/' + pictureId + '?height=' + height + '&client=1&token=' + token;
            }else if(width != undefined){
                result = `${window._CONFIG['domianURL']}` + baseUrl + '/' + pictureId + '?width=' + width + '&client=1&token=' + token;
            }else{
                result = `${window._CONFIG['domianURL']}` + baseUrl + '/' + pictureId + '?client=1&token=' + token;
            }
        }
    }

    return result;
}

/**  根据商品分类的List<TreeSelectModel>列表（树数据）生成Cascader级联组件使用的数据  */
export function getCategoryCascaderData(treeSelectModelList){
    let categoryList = [];

    // 全部
    let allCategory = {};
    allCategory.value = -1;
    allCategory.label = '全部';
    allCategory.children = [];
    categoryList.push(allCategory);

    if(treeSelectModelList != undefined && treeSelectModelList != null && treeSelectModelList.length > 0){
        for(let i=0; i<treeSelectModelList.length; i++){
            let c1 = {};
            let itemOne = treeSelectModelList[i];
            c1.value = itemOne.key;
            c1.label = itemOne.title;
            c1.children = [];
            if(itemOne.leaf == false && itemOne.children != undefined && itemOne.children != null && itemOne.children.length > 0){
                for(let j=0; j< itemOne.children.length; j++){
                    let c2 = {};
                    c2.value = itemOne.children[j].key;
                    c2.label = itemOne.children[j].title;
                    c1.children.push(c2);
                }
            }
            categoryList.push(c1);
        }
    }
    return categoryList;
}


// ------------------------------------------------------------------------------------- 时间 start
/** 获取秒数的时间字符串 */
export function getSecondTimeStr(value) {
    var secondTime = parseInt(value);// 秒
    var minuteTime = 0;// 分
    var hourTime = 0;// 小时
    if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if(minuteTime > 60) {
            //获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            //获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var result = "" + parseInt(secondTime) + "秒";

    if(minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
    }
    if(hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
    }
    return result;
}

// ------------------------------------------------------------------------------------- 时间 end




























