<template>
    <div class="page">
        <div class="page-header" v-show="dShowHeader">
            <img src="~@/assets/logo.png" class="page-header-logo" alt="logo">
            <!--<el-menu :default-active="dCurrentMenuIndex" class="page-header-menu" mode="horizontal" @select="menuSelected">-->
                <!--<el-menu-item index="1" class="page-header-menu-item">首页</el-menu-item>-->
            <!--</el-menu>-->
            <!--<div class="page-header-more">-->
                <!--<el-button @click="test" v-show="false">test</el-button>-->
                <!--<el-avatar class="page-header-more-avatar" :size="30" :src="dAvatarUrl"></el-avatar>-->
                <!--<el-dropdown class="page-header-more-menu" @command="handleUserMenuCommand" >-->
                    <!--<span class="el-dropdown-link page-header-more-menu-top" >-->
                        <!--{{dUserPhone}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
                    <!--</span>-->
                    <!--<el-dropdown-menu slot="dropdown">-->
                        <!--<el-dropdown-item command="1" v-show="false">个人中心</el-dropdown-item>-->
                        <!--<el-dropdown-item command="2">退出登陆</el-dropdown-item>-->
                    <!--</el-dropdown-menu>-->
                <!--</el-dropdown>-->
            <!--</div>-->
        </div>
        <route-view></route-view>
    </div>
</template>

<script>
    import Vue from 'vue';
    import RouteView from "@/components/layouts/RouteView";
    import { userLogout } from "@/api/api";
    import { postAction } from "@/api/api-manage";
    import { getAvatarPictureId } from "@/utils/web-utils.js";
    import { mapActions } from 'vuex';
    import { Loading } from 'element-ui';
    import { USER_PHONE } from "@/store/mutation-types.js";
    import { strIsEmpty } from  "@/utils/my-utils.js";

    export default {
        name: "PageLayout",
        components: {
            RouteView
        },
        data() {
            return {
                dShowHeader:true,
                dCurrentMenuIndex: '1',
                dAvatarUrl: '',
                dUserPhone: '',
                dLoadingInstance: null,
                dLoadingOptions: {
                    target: 'document.body',
                    body: false,
                    fullscreen: true,
                    lock: false,
                    text: ''
                },
            }
        },
        /** 子页面调用方法 */
        provide(){
            return{
                setHeaderIsShow:this.setHeaderIsShow,
                setCurrentNavMenu:this.setCurrentNavMenu,
                setLoading: this.setLoading
            }
        },
        methods: {
            ...mapActions(["userLogout"]),
            /** 初始化 */
            layoutInit:function () {
                let userPhone = Vue.ls.get(USER_PHONE);
                if(!strIsEmpty(userPhone)){
                    this.dUserPhone = userPhone;
                }
            },
            /** 设置Header是否显示 */
            setHeaderIsShow: function(show){
                this.dShowHeader = show;
                console.log('dShowHeader = ' + this.dShowHeader);
            },
            /** 设置当前菜单（子页面调用） */
            setCurrentNavMenu: function(menuIndex){
                this.dCurrentMenuIndex = menuIndex+'';
            },
            /** 菜单被选择处理函数 */
            menuSelected: function(key, keyPath){
                console.log('菜单选择 key = ' + key +  '  currentMenuIndex = ' + this.dCurrentMenuIndex);
                key = key-0;
                switch (key) {
                    case 1:
                        this.$router.push({name:"Index", query:{}});
                        break;
                }
            },
            /** 点击用户菜单处理函数 */
            handleUserMenuCommand: function(command){
                let that = this;
                //this.$message('click on item ' + command);
                command = command-0;
                switch (command) {
                    case 1:

                        break;
                    case 2:
                        this.logout();
                        break;
                }
            },
            /** 退出登陆 */
            logout: function(){
                let that = this;
                console.log('退出登陆');
                this.$confirm('你确认要退出吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    return that.userLogout({}).then(() => {
                        that.$router.push({ path: '/web/user/login' });
                    }).catch(err => {
                        that.$message.error(err.message);
                    });
                }).catch(() => {

                });
            },
            //--------------------------------------------- loading start
            setLoading: function(show, message){
                let that = this;
                this.dLoadingOptions.text = message;
                if(show){
                    if(!this.dLoadingInstance){
                        this.dLoadingInstance = Loading.service(this.dLoadingOptions);
                    }
                }else{
                    if(this.dLoadingInstance){
                        // 以服务的方式调用的 Loading 需要异步关闭
                        this.$nextTick(() => {
                            that.dLoadingInstance.close();
                        });
                    }
                }
            },
            //--------------------------------------------- loadding end
            /** 测试 */
            test:function () {
                //console.log('PageLayout test function call');
                //this.dCurrentMenuIndex = 2 + '';
            },
        },
        created:function(){
            console.log('layout created');
            this.layoutInit();
        },
    }
</script>

<style lang="less" scoped>
.page{
    width: 100%;
    height: 100vh;
    padding: 0px !important;
    display: block;
    .page-header{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 40px !important;
        background-color: transparent !important;
        //background-color: #00ff80;
        border-bottom: 1px solid #eeeeee;
        z-index: 999;
        .page-header-bg {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.1;
        }
        .page-header-logo {
            position: absolute;
            left: 8px;
            top: 5px;
            width: 30px;
            height: 30px;
            -moz-border-radius: 15px;
            -webkit-border-radius: 15px;
            border-radius: 15px;
        }
        .page-header-menu{
            position: absolute;
            left: 50px;
            right: 200px;
            padding-left: 40px;
            width: -webkit-calc(100% - 250px);
            width: -moz-calc(100% - 250px);
            width: calc(100% - 250px);
            height: 100%;
            //background-color: blue;
            .page-header-menu-item{
                height: 40px !important;
                line-height: 40px !important;
            }
        }
        .page-header-more{
            position: absolute;
            display: flex;
            justify-content: flex-end;
            right: 0px;
            width: 200px;
            height: 40px;
            line-height: 40px;
            .page-header-more-avatar{
                margin-top: 5px;
            }
            .page-header-more-menu{
                margin-left: 30px;
                margin-right: 30px;
                .page-header-more-menu-top{
                    height: 40px;
                    line-height: 40px;
                }
                .page-header-more-menu-item{

                }
            }
        }
    }
}
</style>

<style lang="less">
    .leftcontainer{
        margin-top: 100px;
        margin-left: 30px;
        //position: relative;
        width: 600px;
        height: 10px;
        //display: flex;
        align-items: left;
       // justify-content: center;
    }
    .centercontainer{
        margin-top: 60px;
        /*padding-left: 900px;*/
        /*margin-left:300px;*/
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        align-items: right;
        justify-content: center;
    }
    .page-header-bg {
        position: absolute;
        /*left: 0px;*/
        /*top: 0px;*/
        width: 100%;
        height: 70px;
        background-color: black;
    }

    .page-header-logo {
        position: absolute;
        left: 8px;
        top: 3px;
        //width: 20px;
        height: 50px;
        align-items: center;
        -moz-border-radius: 15px;
        -webkit-border-radius: 15px;
        border-radius: 15px;
        justify-content: center;
    }
</style>


