export const ACCESS_TOKEN = 'Access-Token'
export const USER_PHONE = 'Login_phone';
export const USER_NAME = 'Login_Username'
export const USER_INFO = 'Login_Userinfo'


//--------------------------------------------- app start
export const SIDEBAR_TYPE                       = 'SIDEBAR_TYPE'
export const DEFAULT_THEME                      = 'DEFAULT_THEME'
export const DEFAULT_LAYOUT_MODE                = 'DEFAULT_LAYOUT_MODE'
export const DEFAULT_COLOR                      = 'DEFAULT_COLOR'
export const DEFAULT_COLOR_WEAK                 = 'DEFAULT_COLOR_WEAK'
export const DEFAULT_FIXED_HEADER               = 'DEFAULT_FIXED_HEADER'
export const DEFAULT_FIXED_SIDEMENU             = 'DEFAULT_FIXED_SIDEMENU'
export const DEFAULT_FIXED_HEADER_HIDDEN        = 'DEFAULT_FIXED_HEADER_HIDDEN'
export const DEFAULT_CONTENT_WIDTH_TYPE         = 'DEFAULT_CONTENT_WIDTH_TYPE'
export const DEFAULT_MULTI_PAGE                 = 'DEFAULT_MULTI_PAGE'
//--------------------------------------------- app end



