import Vue from 'vue';
import router from './router';
import store from './store';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { generateIndexRouter } from '@/utils/my-utils';
// 进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

//是否使用权限控制
const usePermisssion = false;

// NProgress Configuration
NProgress.configure({ showSpinner: false });
// 免登录白名单
const whiteList = [
    '/user/login', '/user/register', '/user/retrievePassword', '/user/resetPassword',
    '/main/analysis', '/main/workplace'
];


// 路由beforeEach
router.beforeEach((to, from, next) => {
    if (Vue.ls.get(ACCESS_TOKEN)) {
        if (to.path === '/user/login') {
            next({ path: '/main/analysis' });
            NProgress.done();
        }else{
            if (store.getters.permissionList.length === 0) {
                store.dispatch('getPermissionList').then(res => {
                    //console.log('permission getPermissionList res =' + JSON.stringify(res));
                    //next();
                    const menuData = res.result.menu;
                    if (menuData === null || menuData === "" || menuData === undefined) {
                        return;
                    }
                    let constRoutes = [];
                    constRoutes = generateIndexRouter(menuData);
                    //console.log('constRoutes =' + JSON.stringify(constRoutes));
                    // 添加主界面路由
                    store.dispatch('updateAppRouter',  { constRoutes }).then(() => {
                        // 根据roles权限生成可访问的路由表，动态添加可访问路由表
                        router.addRoutes(store.getters.addRouters);
                        //console.log('解码前， from.query.redirect = ' + from.query.redirect);
                        const redirect = decodeURIComponent(from.query.redirect || to.path);
                        //console.log('from =' + from.path + '    to =' + to.path + '   from.query.redirect=' + from.query.redirect + '   redirect = ' + redirect);
                        if (to.path === redirect) {
                            // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                            next({ ...to, replace: true });
                        } else {
                            // 跳转到目的路由
                            next({ path: redirect });
                        }
                    });
                }).catch(() => {
                    store.dispatch('userLogout').then(() => {
                        next({ path: '/user/login', query: { redirect: to.fullPath } })
                    });
                });
            }else{
                next();
            }
        }
    }else{
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next();
        }else{
            next({ path: '/user/login', query: { redirect: to.fullPath } })
            NProgress.done();
        }
    }
});
// 路由afterEach
router.afterEach(() => {
    NProgress.done();
});



