<template>
    <div class="user-wrapper" :class="theme">
        <span class="action" @click="showClick" v-if="false">
            <a-icon type="search"></a-icon>
        </span>
        <component :is="searchMenuComp" v-show="searchMenuVisible || isMobile()" class="borders" :visible="searchMenuVisible" title="搜索菜单" :footer="null" @cancel="searchMenuVisible=false" v-if="false">
            <a-select
                class="search-input"
                showSearch
                :showArrow="false"
                placeholder="搜索菜单"
                optionFilterProp="children"
                :filterOption="filterOption"
                :open="isMobile()?true:null"
                :getPopupContainer="(node) => node.parentNode"
                :style="isMobile()?{width: '100%',marginBottom:'50px'}:{}"
                @change="searchMethods"
                @blur="hiddenClick">
                <a-select-option v-for="(site,index) in searchMenuOptions" :key="index" :value="site.id">{{site.meta.title}}</a-select-option>
            </a-select>
        </component>
        <a-dropdown>
            <span class="action action-full ant-dropdown-link user-dropdown-menu">
                <a-avatar class="avatar" size="small" :src="getAvatar()"/>
                <span v-if="isDesktop()" >欢迎您，{{ realname() }}</span>
            </span>
            <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
                <a-menu-item key="0" v-if="false">
                    <router-link :to="{ name: 'account-center' }">
                        <a-icon type="user"/>
                        <span>个人中心</span>
                    </router-link>
                </a-menu-item>
                <a-menu-item key="1" @click="updatePassword">
                    <a-icon type="setting"/>
                    <span>密码修改</span>
                </a-menu-item>
                <a-menu-item key="2" @click="handleLogout">
                    <a-icon type="setting"/>
                    <span>退出登录</span>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
        <user-password ref="userPassword"></user-password>
    </div>
</template>

<script>

    import Vue from 'vue'
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { mixinDevice } from '@/utils/mixin.js';
    import { postAction,getAction } from "@/api/api-manage"
    import UserPassword from './UserPassword';

    export default {
        name: "UserMenu",
        mixins: [mixinDevice],
        data() {
            return {
                urls:{
                    viewPicture:'',
                },
                searchMenuOptions:[],
                searchMenuComp: 'span',
                searchMenuVisible: false,
            }
        },
        components: {
            UserPassword
        },
        props: {
            theme: {
                type: String,
                required: false,
                default: 'dark'
            }
        },
        created() {
            let lists = []
            this.searchMenus(lists,this.permissionMenuList)
            this.searchMenuOptions=[...lists]
        },
        computed: {
            ...mapState({
                // 后台菜单
                permissionMenuList: state => state.user.permissionList
            })
        },
        watch: {
            // 菜单搜索改为动态组件，在手机端呈现出弹出框
            device: {
                immediate: true,
                handler() {
                    this.searchMenuVisible = false
                    this.searchMenuComp = this.isMobile() ? 'a-modal' : 'span'
                },
            },
        },
        methods: {
            ...mapActions(["userLogout"]),
            ...mapGetters(["realname", "avatar", "userInfo"]),
            showClick() {
                this.searchMenuVisible = true
            },
            hiddenClick(){
                //this.shows = false
            },
            handleLogout() {
                const that = this;
                this.$confirm({
                    title: '提示',
                    content: '真的要注销登录吗 ?',
                    onOk() {
                        return that.userLogout({}).then(() => {
                            that.$router.push({ path: '/user/login' });
                        }).catch(err => {
                            that.$message.error({
                                title: '错误',
                                description: err.message
                            })
                        })
                    },
                    onCancel() {
                    },
                });
            },
            updatePassword(){
                let phone = this.userInfo().phone
                this.$refs.userPassword.show(phone)
            },
            searchMenus(arr,menus){
                for(let i of menus){
                    if(!i.hidden && "layouts/RouteView"!==i.component){
                        arr.push(i)
                    }
                    if(i.children&& i.children.length>0){
                        this.searchMenus(arr,i.children)
                    }
                }
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            // update_begin author:sunjianlei date:20191230 for: 解决外部链接打开失败的问题
            searchMethods(value) {
                let route = this.searchMenuOptions.filter(item => item.id === value)[0]
                if (route.meta.internalOrExternal === true || route.component.includes('layouts/IframePageView')) {
                    window.open(route.meta.url, '_blank')
                } else {
                    this.$router.push({ path: route.path })
                }
                this.searchMenuVisible = false
            },
            getAvatar: function(){
                return '';
            },
            /**
             *  解决外部链接打开失败的问题， 做头部菜单栏导航， 刷新缓存
             */
            clearCache(){
                getAction("sys/dict/refleshCache").then((res) => {
                    if (res.success) {
                        //重新加载缓存
                        getAction("sys/dict/queryAllDictItems").then((res) => {
                            if (res.success) {
                                Vue.ls.remove(UI_CACHE_DB_DICT_DATA)
                                Vue.ls.set(UI_CACHE_DB_DICT_DATA, res.result, 7 * 24 * 60 * 60 * 1000)
                            }
                        })
                        this.$message.success("刷新缓存完成！");
                    }
                }).catch(e=>{
                    this.$message.warn("刷新缓存失败！");
                    console.log("刷新失败",e)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    // 让搜索框颜色能随主题颜色变换,解决全局样式冲突问题
    .user-wrapper .search-input {
        width: 180px;
        color: inherit;

        /deep/ .ant-select-selection {
            background-color: inherit;
            border: 0;
            border-bottom: 1px solid white;
            &__placeholder, &__field__placeholder {
                color: inherit;
            }
        }
    }

    .welcome{
        color: white;
    }

</style>

<style scoped>
    .logout_title {
        color: inherit;
        text-decoration: none;
    }
</style>
