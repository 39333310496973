import Vue from 'vue'
import { USER_INFO} from "@/store/mutation-types"

const getters = {
    device: state => state.app.device,
    token: state => state.user.token,
    avatar: state => {
        state.user.avatar = Vue.ls.get(USER_INFO).avatar;
        return state.user.avatar
    },
    phone: state => state.user.phone,
    username: state => state.user.username,
    realname: state => {
        state.user.realname = Vue.ls.get(USER_INFO).realname;
        return state.user.realname
    },
    permissionList: state => state.user.permissionList,
    userInfo: state => {
        state.user.info = Vue.ls.get(USER_INFO);
        return state.user.info
    },
    addRouters: state => state.permission.addRouters

}


export default getters


