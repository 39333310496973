import { isURL } from '@/utils/validate';
import { ACCESS_TOKEN } from '@/store/mutation-types';



//---------------------------------------------------------------------------------------------------------------------- 页面 star
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
    let event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
}
/**
 * 过滤对象中为空的属性
 */
export function filterObj(obj) {
    if (!(typeof obj == 'object')) {
        return;
    }
    for ( let key in obj) {
        if (obj.hasOwnProperty(key)
            && (obj[key] == null || obj[key] == undefined || obj[key] === '')) {
            delete obj[key];
        }
    }
    return obj;
}
//---------------------------------------------------------------------------------------------------------------------- 页面 end


//---------------------------------------------------------------------------------------------------------------------- 路由 start
// 生成首页路由
export function generateIndexRouter(data) {
    let indexRouter = [{
        path: '/',
        name: 'index',
        component: resolve => require(['@/components/layouts/TabLayout'], resolve),
        meta: { title: '首页' },
        redirect: '/main/analysis',
        children: [
            ...generateChildRouters(data)
        ]
    },{
        "path": "*", "redirect": "/404", "hidden": true
    }]
    return indexRouter;
}
// 生成嵌套路由（子路由）

function  generateChildRouters (data) {
    const routers = [];
    for (let item of data) {
        let component = "";
        if(item.component.indexOf("layouts")>=0){
            component = "components/"+item.component;
        }else{
            component = "views/"+item.component;
        }
        // eslint-disable-next-line
        let URL = (item.url|| '').replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)) // URL支持{{ window.xxx }}占位符变量
        if (isURL(URL)) {
            item.url = URL;
        }
        let componentPath  = resolve => require(['@/' + component+'.vue'], resolve)
        let menu =  {
            path: item.path,
            name: item.name,
            redirect:item.redirect,
            component: componentPath,
            hidden:item.hidden,
            meta: {
                title:item.meta.title ,
                icon: item.meta.icon,
                url:item.meta.url ,
                permissionList:item.meta.permissionList,
                keepAlive:item.meta.keepAlive,
                internalOrExternal:item.meta.internalOrExternal
            }
        }
        if(item.alwaysShow){
            menu.alwaysShow = true;
            menu.redirect = menu.path;
        }
        if (item.children && item.children.length > 0) {
            menu.children = [...generateChildRouters( item.children)];
        }
        //判断是否生成路由
        if(item.route && item.route === '0'){
        }else{
            routers.push(menu);
        }
    }
    return routers
}
//---------------------------------------------------------------------------------------------------------------------- 路由 end



//---------------------------------------------------------------------------------------------------------------------- 字符串 start
/**  判断字符串是否为空 */
export function strIsEmpty(str){
    if(str === undefined || str === null || str.length === 0){
        return true;
    }else{
        return false;
    }
}

/**  验证 */
export function validateValue(pattern, value) {
    let result = true;
    // 6到16位数字
    if(pattern == 'n6-16'){
        result = new RegExp(/^\d{6,18}$/).test(value);
    }
    // 6到16位任意字符
    if(pattern == '*6-16'){
        result = new RegExp(/^.{6,16}$/).test(value);
    }
    // 6到18位字母
    if(pattern == 's6-18'){
        result = new RegExp(/^[a-z|A-Z]{6,18}$/).test(value);
    }
    // 网址
    if(pattern == 'url'){
        result = new RegExp(/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/).test(value);
    }
    // 电子邮件
    if(pattern == 'e'){
        result = new RegExp(/^([\w]+\.*)([\w]+)@[\w]+\.\w{3}(\.\w{2}|)$/).test(value);
    }
    // 手机号码
    if(pattern == 'm'){
        result = new RegExp(/^1[3456789]\d{9}$/).test(value);
    }
    // 邮政编码
    if(pattern == 'p'){
        result = new RegExp(/^[1-9]\d{5}$/).test(value);
    }
    // 字母
    if(pattern == 's'){
        result = new RegExp(/^[A-Z|a-z]+$/).test(value);
    }
    // 数字
    if(pattern == 'n'){
        result = new RegExp(/^-?\d+(\.?\d+|\d?)$/).test(value);
    }
    // 整数
    if(pattern == 'z'){
        result = new RegExp(/^-?\d+$/).test(value);
        // /^[+]{0,1}(\d+)$/
    }
    // 非空
    if(pattern == "*"){
        result = new RegExp( /^.+$/).test(value);
    }
    // 金额
    if(pattern ==  'money'){
        result = new RegExp( /^(([1-9][0-9]*)|([0]\.\d{0,1}|[1-9][0-9]*\.\d{0,1}))$/).test(value);
    }

    return result;
}
//---------------------------------------------------------------------------------------------------------------------- 字符串 end








