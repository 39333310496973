import Vue from 'vue'
import axios from 'axios'
import { VueAxios } from './axios'
import store from '@/store'
import { ACCESS_TOKEN } from "@/store/mutation-types"

/**  指定 axios的 baseURL */
let apiBaseUrl = window._CONFIG['domianURL'] || "/pedu";
/** 创建 axios 实例 */
const service = axios.create({
    baseURL: apiBaseUrl,            // api base_url
    timeout: 1500000                // 请求超时时间   9000
})


/**  暂时没有使用，因为服务区接口使用json格式返回 */
const err = (error) => {
    if (error.response) {
        let that=this;
        let data = error.response.data;
        const token = Vue.ls.get(ACCESS_TOKEN);
        console.log("------异常响应------",token)
        console.log("------异常响应------",error.response.status)
        switch (error.response.status) {
            case 500:
                break;
        }
    }
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
        config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
},(error) => {
    return Promise.reject(error)
})
// response interceptor
service.interceptors.response.use((response) => {
    return response.data
}, err)
const installer = {
    vm: {},
    install (Vue, router = {}) {
        Vue.use(VueAxios, router, service)
    }
}
export {
    installer as VueAxios,
    service as axios
}










