export default {
    title: 'PPTGAME_MANAGER',

    primaryColor: '#1890FF',                    // 默认主题色
    navTheme: 'dark',                          // 两种主题:['dark', 'light']
    layout: 'sidemenu',                         // 两种整体布局方式: ['sidemenu', 'topmenu']
    contentWidth: 'Fixed',                      // 内容区布局： 流式 |  固定
    fixSiderbar: false,                         // 固定左侧菜单栏 ： boolean
    fixedHeader: false,                         // 固定 Header : boolean
    fixedHeaderHide: false,                     // 向下滚动时，隐藏 Header : boolean
    colorWeak: false,                           // 色盲模式
    multipage: true,                            // 默认多页签模式

    storageOptions: {
        namespace: 'pro__',                             // key prefix
        name: 'ls',                                     // name variable Vue.[ls] or this.[$ls],
        storage: 'local',                               // storage name session, local, memory
    }

}
