<template>
    <a-layout-header
        v-if="!headerBarFixed"
        :class="[fixedHeader && 'ant-header-fixedHeader', sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed', ]"
        :style="{ padding: '0' }">
        <div v-if="mode === 'sidemenu'" class="header" :class="theme">
            <a-icon
                v-if="device==='mobile'"
                class="trigger"
                :type="collapsed ? 'menu-fold' : 'menu-unfold'"
                @click="toggle">
            </a-icon>
            <a-icon
                v-else
                class="trigger"
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                @click="toggle"/>
            <span v-if="device === 'desktop'">欢迎您来一起玩！</span>
            <span v-else>pedu</span>
            <user-menu :theme="theme"/>
        </div>
        <!-- 顶部导航栏模式 start -->
        <div v-else :class="['top-nav-header-index', theme]">
            <div class="header-index-wide">
                <div class="header-index-left" :style="topMenuStyle.headerIndexLeft">
                    <logo class="top-nav-header" :show-title="device !== 'mobile'" :style="topMenuStyle.topNavHeader"/>
                    <div v-if="device !== 'mobile'" :style="topMenuStyle.topSmenuStyle">
                        <s-menu
                            mode="horizontal"
                            :menu="menus"
                            :theme="theme"></s-menu>
                    </div>
                    <a-icon
                        v-else
                        class="trigger"
                        :type="collapsed ? 'menu-fold' : 'menu-unfold'"
                        @click="toggle"></a-icon>
                </div>
                <user-menu class="header-index-right" :theme="theme" :style="topMenuStyle.headerIndexRight"/>
            </div>
        </div>
        <!-- 顶部导航栏模式 end -->
    </a-layout-header>
</template>

<script>

    import UserMenu from '../tools/UserMenu';
    import SMenu from '../menu/';
    import Logo from '../tools/Logo';
    import { mixin } from '@/utils/mixin.js';

    export default {
        name: "GlobalHeader",
        components: {
            UserMenu,
            SMenu,
            Logo,
        },
        mixins: [mixin],
        props: {
            mode: {
                type: String,
                // sidemenu, topmenu
                default: 'sidemenu'
            },
            menus: {
                type: Array,
                required: true
            },
            theme: {
                type: String,
                required: false,
                default: 'dark'
            },
            collapsed: {
                type: Boolean,
                required: false,
                default: false
            },
            device: {
                type: String,
                required: false,
                default: 'desktop'
            }
        },
        data() {
            return {
                headerBarFixed: false,
                topMenuStyle: {
                    headerIndexLeft: {},
                    topNavHeader: {},
                    headerIndexRight: {},
                    topSmenuStyle: {}
                },
                chatStatus: '',
            }
        },
        watch: {
            /** 监听设备变化 */
            device() {
                if (this.mode === 'topmenu') {
                    this.buildTopMenuStyle()
                }
            },
            /** 监听导航栏模式变化 */
            mode(newVal) {
                if (newVal === 'topmenu') {
                    this.buildTopMenuStyle()
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll)
            //  顶部导航栏过长时显示更多按钮
            if (this.mode === 'topmenu') {
                this.buildTopMenuStyle()
            }
        },
        created() {
            this.headerInit();
        },
        methods: {
            headerInit: function() {
                console.log('theme mode  = ' + this.mode);
            },
            handleScroll() {
                if (this.autoHideHeader) {
                    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                    if (scrollTop > 100) {
                        this.headerBarFixed = true
                    } else {
                        this.headerBarFixed = false
                    }
                } else {
                    this.headerBarFixed = false
                }
            },
            toggle() {
                this.$emit('toggle')
            },
            /**  顶部导航栏过长时显示更多按钮 */
            buildTopMenuStyle() {
                if (this.mode === 'topmenu') {
                    if (this.device === 'mobile') {
                        // 手机端需要清空样式，否则显示会错乱
                        this.topMenuStyle.topNavHeader = {}
                        this.topMenuStyle.topSmenuStyle = {}
                        this.topMenuStyle.headerIndexRight = {}
                        this.topMenuStyle.headerIndexLeft = {}
                    } else {
                        let rightWidth = '360px'
                        this.topMenuStyle.topNavHeader = { 'min-width': '165px' }
                        this.topMenuStyle.topSmenuStyle = { 'width': 'calc(100% - 165px)' }
                        this.topMenuStyle.headerIndexRight = { 'min-width': rightWidth }
                        this.topMenuStyle.headerIndexLeft = { 'width': `calc(100% - ${rightWidth})` }
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @height: 59px;
    .ant-layout-header {
        height: @height;
        line-height: @height;
    }
    .layout {
        .top-nav-header-index {
            .header-index-wide {
                margin-left: 10px;
                .ant-menu.ant-menu-horizontal {
                    height: @height;
                    line-height: @height;
                }
            }
            .trigger {
                line-height: 64px;
                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }
            }
        }
        .header {
            z-index: 2;
            color: white;
            height: @height;
            background-color: @primary-color;
            transition: background 300ms;
            /* dark 样式 */
            &.dark {
                color: #000000;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
                background-color: white !important;
            }
        }
        .header, .top-nav-header-index {
            &.dark .trigger:hover {
                background: rgba(0, 0, 0, 0.05);
            }
        }
    }
</style>
