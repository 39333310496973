<template>
    <a-modal
        :title="title"
        :width="modalWidth"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        cancelText="关闭">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="旧密码">
                    <a-input type="password" placeholder="请输入旧密码" v-decorator="[ 'oldPassword', validatorRules.oldPassword]" />
                </a-form-item>
                <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="新密码">
                    <a-input type="password" placeholder="请输入新密码" v-decorator="[ 'password', validatorRules.password]" />
                </a-form-item>

                <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="确认新密码">
                    <a-input type="password" @blur="handleConfirmBlur" placeholder="请确认新密码" v-decorator="[ 'rePassword', validatorRules.rePassword]"/>
                </a-form-item>

            </a-form>
        </a-spin>
    </a-modal>
</template>


<script>

    import { postAction } from '@/api/api-manage'

    export default {
        name: "UserPassword",
        data() {
            return {
                title:"修改密码",
                modalWidth:800,
                visible: false,
                confirmLoading: false,
                validatorRules:{
                    oldPassword:{
                        rules: [{
                            required: true, message: '请输入旧密码!',
                        }],
                    },
                    password:{
                        rules: [{
                            required: true, message: '请输入新密码!',
                        }, {
                            validator: this.validateToNextPassword,
                        }],
                    },
                    rePassword:{
                        rules: [{
                            required: true, message: '请确认新密码!',
                        }, {
                            validator: this.compareToFirstPassword,
                        }],
                    }
                },
                confirmDirty:false,
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 5 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },

                form:this.$form.createForm(this),
                url: "/web/user/changePassword",
                phone:"",
            }
        },
        methods: {
            show(phone){
                if(!phone){
                    this.$message.warning("当前系统无登录用户!");
                    return
                }else{
                    this.phone = phone
                    this.form.resetFields();
                    this.visible = true;
                }
            },
            handleCancel () {
                this.close()
            },
            close () {
                this.$emit('close');
                this.visible = false;
            },
            handleOk () {
                const that = this;
                // 触发表单验证
                this.form.validateFields((err, values) => {
                    if (!err) {
                        that.confirmLoading = true;
                        let params = Object.assign({username:this.username}, values)
                        console.log("修改密码提交数据",params)
                        postAction(this.url, params).then((res) => {
                            that.loading = false;
                            console.log('changePassword response:  ' + JSON.stringify(res));
                            if (res.success) {
                                that.$message.success(res.message);
                                that.close();
                            }else{
                                that.$message.warning(res.message);
                            }
                        }).finally(() => {
                            that.confirmLoading = false;
                        });
                    }
                })
            },
            validateToNextPassword  (rule, value, callback) {
                const form = this.form;
                if (value && this.confirmDirty) {
                    form.validateFields(['confirm'], { force: true })
                }
                callback();
            },
            compareToFirstPassword  (rule, value, callback) {
                const form = this.form;
                if (value && value !== form.getFieldValue('password')) {
                    callback('两次输入的密码不一样！');
                } else {
                    callback()
                }
            },
            handleConfirmBlur  (e) {
                const value = e.target.value
                this.confirmDirty = this.confirmDirty || !!value
            }
        }
    }
</script>

<style scoped>

</style>
