import { axios } from '@/utils/request';
import { getAction, postAction} from '@/api/api-manage'


//------------------------------------------------------------------------------------------------------- user start
/** sms */
export  const sms = (params)=>postAction("/web/user/sms",params);

/** 根据手机号码和验证码获取重置密码token */
export const getRetrievePasswordToken = (params)=>postAction("/web/user/getRetrievePasswordToken",params);

/**  重置密码 */
export const resetPassword = (params)=>postAction("/web/user/resetPassword",params);

/** 注册 */
export const register = (params)=>postAction("/web/user/register",params);

/** 检测当前token是否有效 */
export const checkToken = (params)=>postAction("/web/user/checkToken",params);

/** 登录 */
export function login(parameter) {
    return axios({
        url: '/web/user/login',
        method: 'post',
        data: parameter
    })
}

/** 登出 */
export function logout(token) {
    return axios({
        url: '/web/user/logout',
        method: 'post',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Access-Token':  token
        }
    })
}

/** 获取用户权限(暂时未使用) */
export const getUserPermissions = (params)=>postAction("/web/com/getUserPermissions",params);

//------------------------------------------------------------------------------------------------------- user end









