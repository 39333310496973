import Vue from 'vue'
import App from './App.vue'

import { strIsEmpty } from '@/utils/my-utils';
import config from '@/config/default-settings';
import Storage from 'vue-ls';
import store from './store/';
import { VueAxios } from "@/utils/request";
import {
    ACCESS_TOKEN,
    DEFAULT_COLOR,
    DEFAULT_THEME,
    DEFAULT_LAYOUT_MODE,
    DEFAULT_COLOR_WEAK,
    SIDEBAR_TYPE,
    DEFAULT_FIXED_HEADER,
    DEFAULT_FIXED_HEADER_HIDDEN,
    DEFAULT_FIXED_SIDEMENU,
    DEFAULT_CONTENT_WIDTH_TYPE,
    DEFAULT_MULTI_PAGE
} from "@/store/mutation-types"
import '@/permission'                                               // permission control
import router from './router';
//Ueditor修改了单图上传使用了Jquery的Ajax
import $ from 'jquery'

Vue.use(Storage, config.storageOptions);
Vue.use(router);
Vue.use(store);

//-------------------------------------------------- elemenet start
import { Backtop, Alert, Loading} from 'element-ui';
Vue.use(Backtop);
Vue.use(Alert);
Vue.use(Loading);
//Vue.component(MessageBox);
//Vue.prototype.$confirm = MessageBox.confirm;            // vue实例上挂载confirm
//Vue.prototype.$alert = MessageBox.alert;                // vue实例上挂载alert
//-------------------------------------------------- element end

//-------------------------------------------------- ant design start
import { Button, Form, FormModel, Table, Input, InputNumber, Radio, Checkbox, Modal, Drawer, Tabs, Upload, List, Tree, Select, TreeSelect, Cascader, Dropdown, Spin, Icon, Tag, Avatar, Menu, Divider, Layout, Card, Slider, Carousel, Timeline, Switch, Row, Col,  message, Popconfirm} from 'ant-design-vue';
Vue.use(Button);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Table);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Modal);
Vue.use(Drawer);
Vue.use(Tabs);
Vue.use(Upload);
Vue.use(List);
Vue.use(Tree);
Vue.use(Select);
Vue.use(TreeSelect);
Vue.use(Cascader);
Vue.use(Dropdown);
Vue.use(Spin);
Vue.use(Icon);
Vue.use(Tag);
Vue.use(Avatar);
Vue.use(Menu);
Vue.use(Divider);
Vue.use(Layout);
Vue.use(Card);
Vue.use(Slider);
Vue.use(Carousel);
Vue.use(Timeline);
Vue.use(Switch);
Vue.use(Row);
Vue.use(Col);
//Vue.use(BackTop);
Vue.use(message);
Vue.use(Popconfirm);
Vue.prototype.$message = message;                       // vue实例上挂载Message
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
//Vue.prototype.$notification = notification

import 'ant-design-vue/dist/antd.less';                             // or 'ant-design-vue/dist/antd.less'
//-------------------------------------------------- ant design end

Vue.config.productionTip = false;
// ------------------------------------------------------------- Vue.ls.get 默认值不起作用 start
// Vue.ls.get(SIDEBAR_TYPE, true)
let sidebarType = Vue.ls.get(SIDEBAR_TYPE);
if(sidebarType == null){
    sidebarType = true;
}
let namTheme = Vue.ls.get(DEFAULT_THEME);
if(strIsEmpty(namTheme)){
    namTheme = config.navTheme;
}
let layoutMode =  Vue.ls.get(DEFAULT_LAYOUT_MODE);
if(strIsEmpty(layoutMode)){
    layoutMode = config.layout;
}
let fixedHeader = Vue.ls.get(DEFAULT_FIXED_HEADER);
if(fixedHeader == null){
    fixedHeader = Vue.ls.get(DEFAULT_FIXED_HEADER, config.fixedHeader);
}
let fixedHeaderHide = Vue.ls.get(DEFAULT_FIXED_HEADER_HIDDEN);
if(fixedHeaderHide == null){
    fixedHeaderHide = config.fixedHeaderHide;
}
let fixedSidebar = Vue.ls.get(DEFAULT_FIXED_SIDEMENU);
if(fixedSidebar == null){
    fixedSidebar = config.fixSiderbar;
}
let contentWidth =  Vue.ls.get(DEFAULT_CONTENT_WIDTH_TYPE);
if(strIsEmpty(contentWidth)){
    contentWidth = config.contentWidth;
}
let colorWeak = Vue.ls.get(DEFAULT_COLOR_WEAK);
if(colorWeak == null){
    colorWeak = config.colorWeak;
}
let primaryColor = Vue.ls.get(DEFAULT_COLOR);
if(strIsEmpty(primaryColor)){
    primaryColor = config.primaryColor;
}
let multipage = Vue.ls.get(DEFAULT_MULTI_PAGE);
if(multipage == null){
    multipage = config.multipage;
}
// ------------------------------------------------------------- Vue.ls.get 默认值不起作用 end
new Vue({
    //---------------------------------------------------------------------------------------------------------- add start
    router,
    store,
    mounted () {
        store.commit('SET_SIDEBAR_TYPE', sidebarType)
        store.commit('TOGGLE_THEME', namTheme)
        store.commit('TOGGLE_LAYOUT_MODE', layoutMode)
        store.commit('TOGGLE_FIXED_HEADER', fixedHeader)
        store.commit('TOGGLE_FIXED_HEADER_HIDDEN', fixedHeaderHide)
        store.commit('TOGGLE_FIXED_SIDERBAR', fixedSidebar)
        store.commit('TOGGLE_CONTENT_WIDTH', contentWidth)
        store.commit('TOGGLE_WEAK', colorWeak)
        store.commit('TOGGLE_COLOR', primaryColor)
        store.commit('SET_MULTI_PAGE',multipage)
        store.commit('SET_TOKEN', Vue.ls.get(ACCESS_TOKEN))
    },
    //---------------------------------------------------------------------------------------------------------- add end
    render: h => h(App),
}).$mount('#app');
