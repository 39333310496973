import Vue from 'vue';
import {ACCESS_TOKEN, USER_PHONE, USER_INFO} from '@/store/mutation-types';
import { login, logout, getUserPermissions } from "@/api/api"

const  user={
    state: {
        token: '',
        phone: '',
        username: '',
        realname: '',
        avatar: '',
        avatarPictureId: '',
        permissionList: []
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, { phone, username, realname }) => {
            state.phone = phone;
            state.username = username;
            state.realname = realname;
        },
        SET_AVATAR: (state, avatar, avatarPictureId) => {
            state.avatar = avatar
            state.avatarPictureId = avatarPictureId;
        },
        SET_INFO: (state, info) => {
            state.info = info
        },
        SET_PERMISSIONLIST: (state, permissionList) => {
            state.permissionList = permissionList
        },
    },
    actions: {
        // 测试
        mapActionsTest({ commit }, userInfo){
            return new Promise((resolve, reject) => {
                resolve();
            });
        },
        // 登录
        userLogin({ commit }, userInfo){
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    console.log('login result = ' + JSON.stringify(response));
                    if(response.code == 200){
                        const userInfo = response.result;
                        Vue.ls.set(ACCESS_TOKEN, userInfo.token, 7 * 24 * 60 * 60 * 1000)
                        Vue.ls.set(USER_PHONE, userInfo.phone, 7 * 24 * 60 * 60 * 1000)
                        Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
                        commit('SET_TOKEN', userInfo.token)
                        commit('SET_NAME', { phone: userInfo.phone, username: userInfo.username, realname: userInfo.realname })
                        commit('SET_AVATAR', { avatar: userInfo.avatar, avatarPictureId: userInfo.avatarPictureId });
                        commit('SET_INFO', userInfo);
                        resolve(response)
                    }else{
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        userLogout({ commit, state }) {
            return new Promise((resolve) => {
                let logoutToken = state.token;
                commit('SET_TOKEN', '');
                commit('SET_PERMISSIONLIST', []);
                Vue.ls.remove(ACCESS_TOKEN);
                logout(logoutToken).then(() => {
                    resolve()
                }).catch(() => {
                    resolve()
                })
            });
        },
        // 获取用户信息
        getPermissionList({ commit }) {
            return new Promise((resolve, reject) => {
                let token = Vue.ls.get(ACCESS_TOKEN);
                let params = {
                    token:token,
                    module: 'shop'
                };
                getUserPermissions(params).then(response => {
                    //console.log('/store/modules/user.js方法getUserPermissions返回 = ' + JSON.stringify(response));
                    if(response.code == 200 && response.result && response.result.menu && response.result.auth){
                        const menuData = response.result.menu;
                        const authData = response.result.auth;
                        //sessionStorage.setItem(USER_AUTH,JSON.stringify(authData));                   // baijinwen delete
                        if (menuData && menuData.length > 0) {
                            menuData.forEach((item, index) => {
                                if (item["children"]) {
                                    let hasChildrenMenu = item["children"].filter((i) => {
                                        return !i.hidden || i.hidden == false
                                    });
                                    if (hasChildrenMenu == null || hasChildrenMenu.length == 0) {
                                        item["hidden"] = true
                                    }
                                }
                            });
                        }
                        //console.log('getUserPermissions then SET_PERMISSIONLIST = ' + JSON.stringify(menuData) );
                        commit('SET_PERMISSIONLIST', menuData);
                    }else{
                        reject('getPermissionList: permissions must be a non-null array !')
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            });
        },

    }
}


export default user




