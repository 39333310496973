import {PageLayout, TabLayout} from '@/components/layouts'


/**
 * 走菜单，走权限控制
 */
export const asyncRouterMap = [
    {
        path: '/main',
        name: 'Main',
        component: TabLayout,
        meta: { title: '首页' },
        redirect: '/main/analysis',
        children: [
            {
                path: '/main/analysis',
                name: 'Analysis',
                component: () => import('@/views/main/Analysis'),
                meta: { title: '分析页', permission: [ 'main' ] }
            }
        ]
    }
];

/**
 * 基础路由
 */
export const constantRouterMap = [
    {
        path: '/user',
        name: 'User',
        component: PageLayout,
        meta: { title: '用户' },
        redirect: '/user/login',
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/views/user/Login')
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import('@/views/user/Register')
            },
            {
                path: 'retrievePassword',
                name: 'RetrievePassword',
                component: () => import('@/views/user/RetrievePassword')
            },
            {
                path: 'resetPassword',
                name: 'ResetPassword',
                component: () => import('@/views/user/ResetPassword')
            }
        ]
    }
 ];

